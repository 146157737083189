<template>
  <v-card :loading="loading">
    <v-card-title>{{ $t("history.title") }}</v-card-title>
    <v-data-table
      v-if="history"
      :headers="headers"
      :items="filteredHistory"
      :sort-by="['id']"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:item.date="{ item }">
        {{ $d(new Date(item.date), "shortDateTime") }}
      </template>
      <template v-slot:item.type="{ item }">
        {{ getType(item.type) }}
      </template>
      <template v-slot:item.eventId="{ item }">
        <router-link
          v-if="item.eventId"
          :to="{ name: 'Overview', params: { id: item.eventId } }"
          exact
        >
          {{ $t("history.table.toEvent") }}
        </router-link>
      </template>
      <template v-slot:item.deviceId="{ item }">
        <router-link
          v-if="item.deviceId"
          :to="{ name: 'DevicesEdit', params: { id: item.deviceId } }"
          exact
        >
          {{ item.deviceId }}
        </router-link>
      </template>
    </v-data-table>
    <v-card-actions
      v-if="hasPrevPage || hasNextPage"
      class="d-flex justify-space-between"
    >
      <v-btn
        icon
        tile
        color="primary"
        :disabled="!hasPrevPage"
        @click="prevPage"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <span class="grey--text text--darken-2 font-weight-bold">
        {{ currentPage + 1 }}
      </span>

      <v-btn
        icon
        tile
        color="primary"
        :disabled="!hasNextPage"
        @click="nextPage"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
/* eslint-disable */

import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "PanelHistory",
  data() {
    return {
      loading: true,
      itemsPerPage: 10,
      currentPage: 0,
      historySubscription: null
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      history: state => state.timingpoints.history
    }),
    ...mapGetters({
      isConnected: "socket/isConnected"
    }),
    headers() {
      return [
        {
          text: this.$i18n.t("history.table.charge"),
          value: "charge"
        },
        {
          text: this.$i18n.t("history.table.date"),
          value: "date"
        },
        {
          text: this.$i18n.t("history.table.type"),
          value: "type"
        },
        {
          text: this.$i18n.t("history.table.event"),
          value: "eventId"
        },
        {
          text: this.$i18n.t("history.table.device"),
          value: "deviceId"
        }
      ];
    },
    filteredHistory() {
      return this.history ? this.history.slice(0, this.itemsPerPage) : null;
    },
    hasPrevPage() {
      return this.currentPage !== 0;
    },
    hasNextPage() {
      return this.itemsPerPage < this.history.length;
    }
  },
  watch: {
    isConnected(value) {
      if (value) {
        this.initHistorySubscription();
      }
    }
  },
  methods: {
    ...mapActions({
      fetchHistory: "timingpoints/fetchHistory",
      subscribe: "socket/subscribe",
      unsubscribe: "socket/unsubscribe"
    }),
    ...mapMutations({
      addHistoryEntry: "timingpoints/addHistoryEntry",
      updateHistoryEntry: "timingpoints/updateHistoryEntry",
    }),
    getType(type) {
      switch (type) {
        case "DEVICE_REG":
          return this.$i18n.t("history.table.deviceRegistration");
        case "EVENT":
          return this.$i18n.t("history.table.eventCreated");
        case "EVENT_EXEC":
          return this.$i18n.t("history.table.eventExecution");
        case "TEST_TRIGGER":
          return this.$i18n.t("history.table.testTrigger");
        case "SHOP_BUYMENT":
          return this.$i18n.t("history.table.shopBuyment");
        case "ADMIN_DEPOSIT":
          return this.$i18n.t("history.table.adminDeposit");
        case "DEV_MONTH_CHARGE":
          return this.$i18n.t("history.table.devMonthCharge");
        default:
          return type;
      }
    },
    prevPage() {
      this.currentPage -= 1;
      this.fetchPage();
    },
    nextPage() {
      this.currentPage += 1;
      this.fetchPage();
    },
    fetchPage() {
      return new Promise((resolve, reject) => {
        const payload = {
          userId: this.user.id,
          filter: {
            offset: this.itemsPerPage * this.currentPage,
            limit: this.itemsPerPage + 1
          }
        };

        this.fetchHistory(payload)
          .then(() => {
            this.loading = false;
            resolve();
          })
          .catch(() => {
            this.loading = false;
            reject();
          });
        });
    },
    initHistorySubscription() {
      this.subscribe({
        topic: `/topic/user/${this.user.id}/timingpoints/transactions`,
        callback: tick => { this.handleHistorySubscription(JSON.parse(tick.body)) }
      })
        .then(id => this.historySubscription = id);
    },
    handleHistorySubscription(TimingPointTransactionDto) {
      if (TimingPointTransactionDto.type === "ENTITY_ADD") {
        if (this.currentPage === 0) {
          this.addHistoryEntry(TimingPointTransactionDto.dto);
        }
      } else if (TimingPointTransactionDto.type === "ENTITY_UPDATE") {
        this.updateHistoryEntry(TimingPointTransactionDto.dto);
      }
    }
  },
  created() {
    this.fetchPage()
      .then(() => {
        if (this.isConnected) {
          this.initHistorySubscription();
        }
      });
  },
  beforeDestroy() {
    this.unsubscribe(this.historySubscription);
  }
};
</script>
<style scoped>
.v-data-table /deep/ th[role=columnheader] {
  white-space: nowrap;
}
</style>
